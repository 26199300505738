.feature-item {
    padding: 20px;
    border-radius: 20px;
    margin: 0 10px;
    background: linear-gradient(148.75deg, rgba(255, 109, 63, 0.4) -23.05%, rgba(164, 136, 206, 0.01) 91.93%);
}

@media (max-width: 768px) {
    .feature-item {
        width: 320px;
        margin: 0 400px;
    }
}