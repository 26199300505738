.pillar-image {
    height: 300px;
}

.pillar-brand{
    width: 25%;
}

.selected-pillar {
    height: 300px;
}

.deselected-pillar {
    height: 150px;
    opacity: 0.6;
    position: relative;
    top: 100px;
}

.pillar-container {
    width: 70%;
}

.pillar-description {
    padding: 3% 10%;
}

@media (max-width: 768px) {

    .pillar-image {
        height: 70px;
    }

    .selected-pillar {
        height: 180px;
    }

    .deselected-pillar {
        position: relative;
        top: 50px;
    }

    .pillar-container {
        width: 90%;
    }


    .pillar-description {
        padding: 19% 5%;
        text-align: justify;
    }


    .pillar-brand{
        width: 50%;
    }

}