#header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    max-height: 100px;
}

/* #header>* {
    border: 1px solid gray
} */

#download {
    border: none;
    border-radius: 6px;
    background: #FF6D3F;
    color: #FFF;
    font-weight: 00 !important;
    font-family: "Exo 2", sans-serif;
    min-width: 120px;
    padding: 8px 12px;
    max-height: 80px !important;
}

#sfg-logo-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
    top: -8px;
}

.logo-container {
    flex: 1;
    display: flex;
    justify-content: center;
}

#sfg-logo-text h3 {
    display: inline;
}

#sfg-logo-image {
    height: 44px;
    width: 54px;
}

#event {
    margin-top: 50px !important;
    width: 90%;
    height: max-content;
    margin: auto;
    border: 1px solid rgb(210, 127, 127);
    border-radius: 20px;
}

.event-image img {
    padding: 20px;
    width: 100%;
}

.event-text {
    padding: 20px;
    width: 90%;
    text-align: center !important;
}

#event>.event-container {}

#hero {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
    padding: 20px;
}

#fam-pic .family-image {
    width: 100%;
    background: url('/src/Assets/Group 1707479701.png') center center no-repeat;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
}

#fam-pic>.family-image>.family-text {
    color: white;
    width: 80%;
    padding: 20px;
    font-size: 1.8rem;
    position: relative;
    top: 40%;
    vertical-align: baseline;
}

#pillars {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#sakal {
    margin-top: 100px;
    margin-bottom: 100px;
}

#sakal .img-fluid {
    height: 30%;
    display: block;
}

#material {
    margin-top: 100px;
    margin-bottom: 100px;
}

@media (max-width: 768px) {

    #download {
        border: none;
        border-radius: 6px;
        font-weight: 600 !important;
        font-family: "Exo 2", sans-serif;
        padding: 6px 12px !important;
    }

    #sfg-logo-text {
        display: none;
    }


    .logo-container {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
    }

    #header {
        justify-content: space-between;
    }

    #event {
        margin-top: 30px !important;
        width: 90%;
        padding: 0;
    }

    .event-image img {
        width: 80%;
    }

    .event-image img {
        width: 100%;
    }

    .event-text {
        width: 100%;
    }

    #event>.event-container {
        flex-wrap: wrap;
    }


    #fam-pic>.family-image>.family-text {
        color: white;
        width: 90%;
        padding: 20px;
        font-size: 1.2rem;
        position: relative;
        top: 20%;
        vertical-align: baseline;
    }

    #sakal .img-fluid {
        height: 30%;
        margin-left: 10px;
        margin-right: 10px;
        display: inline-block;
    }

}